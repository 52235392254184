/* eslint-disable no-underscore-dangle */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef, useState } from 'react';
import './workflowCard.scss';
import { useDispatch, useSelector } from 'react-redux';
import Default from '../../../assets/icons/default.svg';
import CloseIcon from '../../../assets/icons/closeIcon.png';
import { ApiGet } from '../../../helper/helpers/api/apiData';
import SubWorkflowTable from '../workflowModal/subWorkflowTable';
import {
  selectVariable,
  getSubWorkflow,
  sendTableData,
  setSelectedId,
  setSubWTableData,
} from '../../../redux/reducer/workflow';
import WorkflowTitle from '../workflowTitle';
import Loader from '../../../components/loader';
import {
  OccationTable,
  UserProfileTable,
  IdentityTable,
  ImmunityTable,
  IconMap,
  ColumnTitles,
  ColumnName,
} from '../../../helper/constant';
import WorkflowCardTitle from './workflowCardTitle';
import useOnClickOutside from '../../../helper/constant/outsideClick';

export default function WorkflowCard() {
  const { getwFlowData, getSelectedId, subData } = useSelector(
    (state) => state.workflow,
  );
  const dispatch = useDispatch();
  const outsideRef = useRef(null);
  const [modalOpen, setModalOpen] = useState('');
  const [selectedIds, setSelectedIds] = useState([]);
  const [loader, setLoader] = useState(false);
  const [key, setKey] = useState();

  // To close dropdown after outside click

  const onCancel = () => {
    setModalOpen('');
  };

  useOnClickOutside(outsideRef, onCancel);

  // To clear selected check value after calling api

  useEffect(() => {
    if (!getSelectedId) {
      setSelectedIds([]);
    }
  }, [getSelectedId]);

  // get workflow sub component data

  const handleSubComponentData = async (e, occasion, i) => {
    setLoader(true);
    e.preventDefault();
    setKey(i);
    await ApiGet(`admin/get-workflow-sub-component?type=${occasion}`)
      .then((res) => {
        dispatch(getSubWorkflow(res?.data?.data?.workflowSubComp_data));
        setModalOpen(`${occasion}`);
        setLoader(false);
      })
      .catch((err) => {
        console.log('err', err);
        setLoader(false);
      });
  };

  // send occasion data to table

  const sendOccationData = async (e, data) => {
    {
      data === 'occasion' && dispatch(sendTableData(OccationTable));
    }
    {
      data === 'user_profile' && dispatch(sendTableData(UserProfileTable));
    }
    {
      data === 'identity' && dispatch(sendTableData(IdentityTable));
    }
    {
      data === 'proof_of_immunity' && dispatch(sendTableData(ImmunityTable));
    }
  };

  // to select workflow id

  const handleSelectId = (e, id) => {
    if (selectedIds.find((f) => f === id)) {
      setSelectedIds(selectedIds.filter((f) => f !== id));
      dispatch(setSelectedId(selectedIds.filter((f) => f !== id)));
    } else {
      setSelectedIds([...selectedIds, id]);
      dispatch(setSelectedId([...selectedIds, id]));
    }
  };

  return (
    <>
      <div className="workflow-card-all-content-alignment">
        <WorkflowTitle />
        {getwFlowData?.workflowComp_data?.length > 0
          && getwFlowData?.workflowComp_data?.map((data, i) => (
              // eslint-disable-next-line react/jsx-key
              <div className="sec-row-flex">
                <div className="sec-row-flex-items">
                  <div>
                    <input
                      type="checkbox"
                      value={data?._id}
                      checked={selectedIds?.find((f) => f === data?._id)}
                      onChange={(e) => handleSelectId(e, data?._id)}
                    />
                  </div>
                </div>
                <WorkflowCardTitle i={i} datas={data} />
                {ColumnTitles?.map((f) => (
                    <>
                      {data?.[f]?.title ? (
                        <div
                          className="sec-row-flex-items"
                          onClick={(e) => {
                            sendOccationData(e, f);
                          }}
                        >
                          <div className="white-box">
                            <div>
                              <img src={IconMap?.[f]} alt="Icon" />
                            </div>
                            <div>
                              <p
                                onClick={(e) => {
                                  setModalOpen('open');
                                  sendOccationData(e, f);
                                  dispatch(
                                    selectVariable({
                                      id: data?._id,
                                      action: 'editVarible',
                                      titleType: data?.[f],
                                    }),
                                  );
                                  const editVariableArray = [];
                                  {
                                    data?.[f]?.data?.map((h) => {
                                      editVariableArray.push(h?.field);
                                    });
                                  }
                                  dispatch(setSubWTableData(editVariableArray));
                                }}
                              >
                                {data?.[f]?.title}
                              </p>
                              <div
                                className="close-button-alignment"
                                onClick={(e) => dispatch(
                                  selectVariable({
                                    id: data?._id,
                                    type: { [f]: '' },
                                    action: 'remove',
                                  }),
                                )
                                }
                              >
                                <img src={CloseIcon} alt="CloseIcon" />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="sec-row-flex-items empty-all-text-alignment">
                          {modalOpen === f && key === i ? (
                            <div
                              className="empty-hover-alignment-active"
                              key={i}
                            >
                              <span
                                onClick={() => {
                                  setModalOpen('');
                                }}
                              >
                                +
                              </span>
                            </div>
                          ) : (
                            <div className="empty-hover-alignment" key={i}>
                              <p>Empty</p>
                              <span
                                onClick={(e) => {
                                  if (
                                    !(f === 'detection' || f === 'prevention')
                                  ) {
                                    handleSubComponentData(e, f, i);
                                    sendOccationData(e, f);
                                    setModalOpen(f);
                                  }
                                }}
                              >
                                +
                              </span>
                            </div>
                          )}

                          {modalOpen === f && key === i && (
                            <>
                              <div
                                className="dropdown-modal-alignment"
                                ref={outsideRef}
                                key={i}
                              >
                                {key === i && (
                                  <>
                                    <div
                                      className="modal-box-alignment"
                                      onClick={(e) => {
                                        setModalOpen('open');
                                        sendOccationData(e, f);
                                        dispatch(
                                          selectVariable({
                                            id: data?._id,
                                            action: 'addVariable',
                                          }),
                                        );
                                      }}
                                    >
                                      <div className="main-alignment">
                                        <div>
                                          <img src={Default} alt="Default" />
                                        </div>
                                        <div className="heading-alignment">
                                          <h4>New {`${ColumnName?.[f]}`}</h4>
                                        </div>
                                      </div>
                                      <div className="child-notes-alignment">
                                        <p>Define new {`${ColumnName?.[f]}`}</p>
                                      </div>
                                    </div>
                                  </>
                                )}
                                {
                                  loader === true ? <Loader />
                                    : subData?.length > 0
                                    && key === i
                                    && subData?.map((res) => (
                                        <>
                                          <div
                                            className="modal-box-alignment"
                                            key={i}
                                            onClick={(e) => {
                                              dispatch(
                                                selectVariable({
                                                  id: data?._id,
                                                  type: { [f]: res?._id },
                                                  action: 'selectVariable',
                                                }),
                                              );
                                            }}
                                          >
                                            <div
                                              className="main-alignment"
                                              // eslint-disable-next-line no-underscore-dangle
                                              key={data._id}
                                            >
                                              <div>
                                                <img
                                                  src={IconMap?.[f]}
                                                  alt="Icon"
                                                />
                                              </div>
                                              <div className="heading-alignment">
                                                <h4>{res?.title}</h4>
                                              </div>
                                            </div>
                                            <div className="child-notes-alignment">
                                              <p>Endemic malaria areas</p>
                                            </div>
                                          </div>
                                        </>
                                    ))

                                }

                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </>
                ))}
              </div>
          ))}
      </div>
      {modalOpen === 'open' && (
        <SubWorkflowTable modalOpen={modalOpen} setModalOpen={setModalOpen} />
      )}
    </>
  );
}
