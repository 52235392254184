/* eslint-disable react/jsx-key */
import React from 'react';
import '../workflowCard/workflowCard.scss';

export default function WorkflowTitle() {
  const Titles = [
    'Title',
    'Occasion',
    'User profile',
    'Identity',
    'Proof of Immunity',
    'Detection',
    'Prevention',
  ];

  return (
    <>
      <div className="firt-row-flex">
        <div className="firt-row-flex-items"></div>
        {Titles?.map((data) => (
            <div className="firt-row-flex-items">
              <p>{data}</p>
            </div>
        ))}
      </div>
    </>
  );
}
