/* eslint-disable react/react-in-jsx-scope */
import './App.css';
import './styles/mixins/global.scss';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </>
  );
}

export default App;
