/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserInputModal from '../userInputModal';
import IdentityInputModal from '../identityInputModal';
import ImmunityInputModal from '../immunityInputModal';
import SubInputModal from '../subInputModal';
import { editVariableInput, sendRowVariable } from '../../../../../redux/reducer/workflow';
import useOnClickOutside from '../../../../../helper/constant/outsideClick';

export default function SubworkflowTableData(props) {
  const { tableData, getRowVariable, getEditInput } = useSelector((state) => state.workflow);
  const {
    res, i, handleOnChangeData, rowKey, setRowKey,
  } = props;
  const [showInput, setShowInput] = useState('');
  const outsideVarRow = useRef(null);

  const dispatch = useDispatch();

  // To call variable popup action copy or delete row

  const handleAction = (e, res, i) => {
    e.preventDefault();
    setRowKey(i);
    dispatch(sendRowVariable({ row: res, index: i }));
  };

  //   To open input modal in varibale input section

  useEffect(() => {
    if (getEditInput === 'Edit' && getRowVariable?.index === i) {
      openShowInput();
    }
  }, [getEditInput]);

  const openShowInput = () => {
    setShowInput(tableData?.type);
  };

  // remove selection after outside row click

  const onCancel = () => {
    // dispatch(sendRowVariable({}));
    setRowKey(null);
  };

  useOnClickOutside(outsideVarRow, onCancel);

  const closeShowInput = () => {
    setShowInput(false);
    dispatch(editVariableInput(''));
    setRowKey(null);
    // dispatch(sendRowVariable({}));
  };

  return (
    <>
      {tableData?.type === 'occasion' && (
        <tr
          key={i}
          onClick={(e) => {
            handleAction(e, res, i);
          }}
          className={i === rowKey ? 'activeRow' : ''}
          onDoubleClick={() => openShowInput()}
          ref={outsideVarRow}
        >
          <td align="left">
            <span>{res?.title ? res?.title : 'Empty'}</span>
          </td>
          <td align="left">
            <span>{res?.commingFrom ? res?.commingFrom : 'Empty'}</span>
          </td>

          <td align="left">
            <span> {res?.travellingTo ? res?.travellingTo : 'Empty'} </span>
          </td>
          <td align="left">
            <span> {res?.reason ? res?.reason : 'Empty'} </span>
          </td>
          <td align="left">
            <span> {res?.transportation ? res?.transportation : 'Empty'} </span>
          </td>
        </tr>
      )}
      {tableData?.type === 'user_profile' && (
        <tr
          key={i}
          onClick={(e) => {
            handleAction(e, res, i);
          }}
          className={i === rowKey ? 'activeRow' : ''}
          onDoubleClick={() => openShowInput()}
          ref={outsideVarRow}
        >
          <td align="left">
            <span>{res?.title ? res?.title : 'Empty'}</span>
          </td>
          <td align="left">
            <span>{res?.id ? res?.id : 'Empty'}</span>
          </td>
          <td align="left">
            <span>{res?.ageLimits ? res?.ageLimits : 'Empty'}</span>
          </td>

          <td align="left">
            <span> {res?.gender ? res?.gender : 'Empty'} </span>
          </td>
          <td align="left">
            <span> {res?.Citizenship ? res?.Citizenship : 'Empty'} </span>
          </td>
          <td align="left">
            <span>
              {' '}
              {res?.physiologicalConditions
                ? res?.physiologicalConditions
                : 'Empty'}{' '}
            </span>
          </td>
          <td align="left">
            <span>
              {res?.healthConditions ? res?.healthConditions : 'Empty'}{' '}
            </span>
          </td>
          <td align="left">
            <span>
              {res?.Livingconditions ? res?.Livingconditions : 'Empty'}{' '}
            </span>
          </td>
          <td align="left">
            <span>
              {res?.Workingconditions ? res?.Workingconditions : 'Empty'}{' '}
            </span>
          </td>
        </tr>
      )}

      {tableData?.type === 'identity' && (
        <tr
          key={i}
          onClick={(e) => {
            handleAction(e, res, i);
          }}
          className={i === rowKey ? 'activeRow' : ''}
          onDoubleClick={() => openShowInput()}
          ref={outsideVarRow}
        >
          <td align="left">
            <span>{res?.userGroup ? res?.userGroup : 'Empty'}</span>
          </td>
          <td align="left">
            <span>{res?.ageLimits ? res?.ageLimits : 'Empty'}</span>
          </td>

          <td align="left">
            <span> {res?.gender ? res?.gender : 'Empty'} </span>
          </td>
          <td align="left">
            <span>
              {' '}
              {res?.physiologicalConditions
                ? res?.physiologicalConditions
                : 'Empty'}{' '}
            </span>
          </td>
          <td align="left">
            <span>
              {' '}
              {res?.healthConditions ? res?.healthConditions : 'Empty'}{' '}
            </span>
          </td>
          {/* <td align="left">
            <span>{res?.comments ? res?.comments : "Empty"}</span>
          </td> */}
        </tr>
      )}

      {tableData?.type === 'proof_of_immunity' && (
        <tr
          key={i}
          onClick={(e) => {
            handleAction(e, res, i);
          }}
          className={i === rowKey ? 'activeRow' : ''}
          onDoubleClick={() => openShowInput()}
          ref={outsideVarRow}
        >
          <td align="left">
            <span>{res?.vaccine ? res?.vaccine : 'Empty'}</span>
          </td>
          <td align="left">
            <span>{res?.minDoses ? res?.minDoses : 'Empty'}</span>
          </td>

          <td align="left">
            <span> {res?.minDays ? res?.minDays : 'Empty'} </span>
          </td>
          <td align="left">
            <span> {res?.Validfor ? res?.Validfor : 'Empty'} </span>
          </td>
          <td align="left">
            <span> {res?.datasource ? res?.datasource : 'Empty'} </span>
          </td>
          {/* <td align="left">
            <span>{res?.comments ? res?.comments : "Empty"}</span>
          </td> */}
        </tr>

      )}

      {showInput === 'occasion' && (
        <SubInputModal
          rowData={res}
          i={i}
          onClose={closeShowInput}
          handleOnChangeData={handleOnChangeData}
        />
      )}
      {showInput === 'user_profile' && (
        <UserInputModal
          rowData={res}
          i={i}
          onClose={closeShowInput}
          handleOnChangeData={handleOnChangeData}
        />
      )}
      {showInput === 'identity' && (
        <IdentityInputModal
          rowData={res}
          i={i}
          onClose={closeShowInput}
          handleOnChangeData={handleOnChangeData}
        />
      )}
      {showInput === 'proof_of_immunity' && (
        <ImmunityInputModal
          rowData={res}
          i={i}
          onClose={closeShowInput}
          handleOnChangeData={handleOnChangeData}
        />
      )}

    </>
  );
}
