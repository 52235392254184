/* eslint-disable import/prefer-default-export */
import { configureStore } from '@reduxjs/toolkit';
import CredentialsReducer from './reducer/credentials';
import UpdateWorkflowReducer from './reducer/updateWorkflow';
import WrokflowReducer from './reducer/workflow';

export const store = configureStore({
  reducer: {
    credential: CredentialsReducer,
    workflow: WrokflowReducer,
    UpdateWorkflow: UpdateWorkflowReducer,

  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});
