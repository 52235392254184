/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable no-use-before-define */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable no-redeclare */
/* eslint-disable block-scoped-var */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import {
  editVariableInput,
  sendRowVariable,
  setSubWTableData,
} from '../../../../redux/reducer/workflow';
import '../workflowModal.scss';
import {
  IdentityField,
  ImmunityField,
  occationFieldname,
  UserProfileFieldName,
} from '../../../../helper/constant';
import CopyrightIcon from '../../../../assets/icons/copyright.svg';
import DeleteIcon from '../../../../assets/icons/delete.svg';
import EditIcon from '../../../../assets/icons/editIcon.png';
import { Tooltip } from '@mui/material';
import RemoveModal from '../../../../components/removeModal';

export default function SubTableHeader() {
  const {
    data, tableData, getTableData, getRowVariable,
  } = useSelector(
    (state) => state.workflow,
  );
  const dispatch = useDispatch();
  const [removeModal, setRemoveModal] = useState(false);

  // To add new row

  const handleAddNewRow = (e) => {
    e.preventDefault();
    if (tableData?.type === 'occasion') {
      dispatch(setSubWTableData([...getTableData, occationFieldname]));
    } else if (tableData?.type === 'user_profile') {
      dispatch(setSubWTableData([...getTableData, UserProfileFieldName]));
    } else if (tableData?.type === 'identity') {
      dispatch(setSubWTableData([...getTableData, IdentityField]));
    } else if (tableData?.type === 'proof_of_immunity') {
      dispatch(setSubWTableData([...getTableData, ImmunityField]));
    }
  };

  // To copy row

  const handleCopyVariableRow = () => {
    if (!getRowVariable?.row) {
      toast.error('Please select row');
    }
    if (getTableData && getRowVariable?.row) {
      dispatch(setSubWTableData([...getTableData, getRowVariable?.row]));
      dispatch(sendRowVariable({}));
    }
  };

  // To delete row\

  const handleDeletRow = () => {
    if (!getRowVariable?.row) {
      toast.error('Please select row');
    } else {
      setRemoveModal(true);
    }
  };

  const handleDeleteVariableRow = () => {
    if (getRowVariable && getTableData) {
      const deleteData = getTableData?.filter(
        (item, i) => i !== getRowVariable?.index,
      );
      dispatch(setSubWTableData([...deleteData]));
      dispatch(sendRowVariable({}));
    }
    handleClose();
  };

  const handleClose = () => {
    setRemoveModal(false);
    dispatch(sendRowVariable({}));
  };

  let TableTitle = '';

  if (tableData?.type === 'occasion') {
    TableTitle = 'Occasion information';
  } else if (tableData?.type === 'user_profile') {
    TableTitle = 'User information';
  } else if (tableData?.type === 'identity') {
    TableTitle = 'Identity information';
  } else if (tableData?.type === 'proof_of_immunity') {
    TableTitle = 'Vaccine accepted*';
  }

  return (
    <>
      <div className="disease-text">
        <span>Disease</span>
      </div>
      <div className="covid-test-text">
        <p>{data?.disease}</p>
      </div>
      <div className="new-row-add-box">
        <div className="right-alignment-icon">
          <p>{TableTitle}</p>
        </div>
        <div className="left-alignment-icon">
          <>
            <Tooltip title="Edit row">
              <img
                src={EditIcon}
                alt="EditIcon"
                onClick={(e) => {
                  dispatch(editVariableInput('Edit'));
                }}
              />
            </Tooltip>
          </>
          <>
            <Tooltip title="Copy row">
              <img
                src={CopyrightIcon}
                alt="CopyrightIcon"
                onClick={(e) => {
                  handleCopyVariableRow(e);
                }}
              />
            </Tooltip>
          </>
          <>
            <Tooltip title="Remove row">
              <img
                src={DeleteIcon}
                alt="DeleteIcon"
                onClick={() => handleDeletRow()}
              />
            </Tooltip>
          </>
          <a
            onClick={(e) => {
              handleAddNewRow(e);
            }}
          >
            + New row
          </a>
        </div>
      </div>
      {removeModal === true && (
        <RemoveModal onRemove={handleDeleteVariableRow} onClose={handleClose}
        title=" Are you sure you want to delete all  information in this row?"
        description="This action can not be undone"
        cancel="Cancel"
        approve="Yes, delete it!"
        />
      )}
    </>
  );
}
