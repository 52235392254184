/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useOnClickOutside from '../../../../helper/constant/outsideClick';
import useDebounce from '../../../../helper/constant/usedeBounceHook';
import { selectVariable } from '../../../../redux/reducer/workflow';
import '../workflowCard.scss';

export default function WorkflowCardTitle(props) {
  const { datas, i } = props;
  const { data, selectVarId } = useSelector((state) => state.workflow);
  const [titleText, setTitleText] = useState({});
  const dispatch = useDispatch();
  const debouncedTitle = useDebounce(titleText, 500);
  const outsideRef = useRef();
  const titleValue = titleText.name === i ? titleText.value : datas?.title;

  //   To clear input title data after sending response in api

  const onCancel = () => {
    setTitleText({});
  };
  useOnClickOutside(outsideRef, onCancel);

  useEffect(() => {
    dispatch(
      selectVariable({
        text: titleText,
        action: 'addTitle',
      }),
    );
  }, [debouncedTitle?.value]);

  //   To change name of workflow row title on enter

  const handleChangeTitle = (e, id) => {
    e.preventDefault();
    const { name, value } = e.target;
    setTitleText({ name, value, id });
    // if (e.key === "Enter" || e.keyCode === 13) {

    // }
  };

  return (
    <>
      <div className="sec-row-flex-items">
        {data?._id === datas?.diseaseId?._id && (
          <div className="row-input-text" ref={outsideRef}>
            <textarea
              placeholder="Please enter title"
              id={i}
              name={i}
              value={
              !titleText.name
                ? datas?.title
                  ? datas?.title
                  : ''
                : titleValue
              }
              onChange={(e) => handleChangeTitle(e, datas?._id)}
              // onKeyDown={(e) => handleChangeTitle(e, datas?._id)}
            />
          </div>
        )}
      </div>
    </>
  );
}
