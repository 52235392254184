/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import ConfirmationIcon from '../../assets/icons/Confirmation.svg';
import { ApiGet, ApiPut } from '../../helper/helpers/api/apiData';
import ProfileIcon from '../../helper/iconColor/porofileIcon';
import Authentication from '../../assets/icons/authentication.svg';
import './createProfile.scss';

export default function CreateProfile() {
  const history = useHistory();
  const [profileData, setProfileData] = useState({ firstName: '', lastName: '', roleId: '' });
  const [errors, setErrors] = useState('');
  const [roleData, setRoleData] = useState([]);
  const userId = JSON.parse(localStorage.getItem('user'));

  const validateForm = () => {
    let formIsValid = true;
    const error = {};
    if (profileData && !profileData?.firstName?.trim()) {
      formIsValid = false;
      error.firstName = '*Please enter first name!';
    }
    if (profileData && !profileData?.lastName?.trim()) {
      formIsValid = false;
      error.lastName = '*Please enter last name!';
    }
    if (profileData && !profileData?.roleId) {
      formIsValid = false;
      error.roleId = '*Please select role!';
    }
    setErrors(error);
    return formIsValid;
  };

  const handleGetRole = async () => {
    await ApiGet('admin/get-role')
      .then((res) => {
        setRoleData(res?.data?.message);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  useEffect(() => {
    handleGetRole();
  }, []);

  const handleChange = (e) => {
    setProfileData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const handleSignup = async () => {
    if (validateForm()) {
      const body = {
        firstName: profileData.firstName,
        lastName: profileData.lastName,
        roleId: profileData.roleId,
        userId: userId?.id,
      };
      await ApiPut('admin/editAdminUser', body)
        .then(async (res) => {
          localStorage.setItem('user', JSON.stringify(res?.data?.message));
          localStorage.setItem('x-access-token', JSON.stringify(res?.data?.message?.token));
          await ApiGet(`admin/qrcode?userId=${userId?.id}`)
            .then((f) => {
              localStorage.setItem('qrcode', f.data.url);
              toast.success('Profile added successfully');
              history.push('/2fa');
            })
            .catch((err) => {
              toast.error(err);
            });
        })
        .catch((err) => {
          console.log('err', err);
          toast.error(err);
        });
    }
  };
  return (
        <>
        <Toaster/>
            <div className="create-profile-all-contnet-alignment">
                <div className="create-profile">
                    <div className="text-heading-style">
                        <h5>Create profile</h5>
                    </div>
                    <div className="icon-text-all-content-alignment">
                        <div className="icon-text">
                            <div>
                            <ProfileIcon color="#3CB44B" filled="filled"/>
                            </div>
                            <div>
                                <span>CREATE ACCOUNT</span>
                            </div>
                        </div>
                        <div className="icon-text">
                            <div>
                            <ProfileIcon color="#3CB44B"/>
                            </div>
                            <div>
                                <span>CREATE PROFILE</span>
                            </div>
                        </div>
                        <div className="icon-text">
                            <div>
                            <img src={Authentication} alt="Authentication"/>
                            </div>
                            <div>
                                <span>ENABLE 2FA </span>
                            </div>
                        </div>
                    </div>
                    <div className="two-col-grid">
                        <div className="two-col-grid-items">
                            <div className="main-input-content">
                                <div className="label-content-alignment">
                                    <label>First name</label>
                                    <span>*</span>
                                </div>
                                <div className="input-design">
                                <input placeholder="Enter your name"
                                name="firstName" value={profileData?.firstName} onChange={(e) => handleChange(e)} />
                                <span
                                style={{
                                  color: 'red',
                                  top: '5px',
                                  fontSize: '12px',

                                }}
                                >
                                {errors.firstName}
                                </span>
                                </div>
                            </div>
                        </div>
                        <div className="two-col-grid-items">
                            <div className="main-input-content">
                                <div className="label-content-alignment">
                                    <label>Last name</label>
                                    <span>*</span>
                                </div>
                                <div className="input-design">
                                <input placeholder="Enter your last name"
                                name="lastName" value={profileData?.lastName} onChange={(e) => handleChange(e)} />
                                <span
                                style={{
                                  color: 'red',
                                  top: '5px',
                                  fontSize: '12px',
                                }}
                                >
                                {errors.lastName}
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-input-content">
                        <div className="label-content-alignment">
                            <label>Role</label>
                            <span>*</span>
                        </div>
                        <div className="input-design">
                        <select id="roleId" name="roleId" value={profileData?.roleId} onChange={(e) => handleChange(e)} >
                        <option
                        value=""
                        selected={profileData?.roleId === ''}>Select role</option>
                        {roleData?.map((f) => (
                            <>
                                <option value={f?._id}>{f?.name}</option>
                            </>
                        ))}
                        </select>
                        <span
                        style={{
                          color: 'red',
                          top: '5px',
                          fontSize: '12px',
                        }}
                        >
                        {errors.roleId}
                        </span>
                        </div>
                    </div>
                    <div className="login-button-center-alignment">
                        <button onClick={handleSignup}>Next</button>
                    </div>
                    <div className="signing-text">
                        <p>By signing up you accept <a>terms and privacy policy</a> </p>
                    </div>
                </div>
            </div>
        </>
  );
}
