// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 20px;
  height: 20px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #7e7e7e;
  border-radius: 50%;
  animation: spinner 0.9s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/loader/loader.scss"],"names":[],"mappings":"AAAA;EACI;IACE,uBAAA;EACJ;EACE;IACE,yBAAA;EACJ;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,yBAAA;EACA,6BAAA;EACA,kBAAA;EACA,uCAAA;AACJ;;AAEE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AACJ","sourcesContent":["@keyframes spinner {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n  .loading-spinner {\n    width: 20px;\n    height: 20px;\n    border: 3px solid #f3f3f3;  \n    border-top: 3px solid #7e7e7e;\n    border-radius: 50%;\n    animation: spinner 0.9s linear infinite;\n  }\n  \n  .spinner-container {\n    display: grid;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
