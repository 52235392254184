/* eslint-disable react/jsx-key */
/* eslint-disable no-lone-blocks */
/* eslint-disable block-scoped-var */
/* eslint-disable no-unused-expressions */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import './editAttachment.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import toast, { Toaster } from 'react-hot-toast';
import { Tooltip } from '@mui/material';
import Attach from '../../../assets/icons/Group.svg';
import EditpopUp from '../../../assets/icons/editpopUp.svg';
import Delete from '../../../assets/icons/delete.svg';
import UploadDropzone from '../uploadDropzone';
import { ApiGet, ApiPatch } from '../../../helper/helpers/api/apiData';
import {
  getApiCount,
  sendDeleteId,
  sendWorkFlowData,
  setRemoveFileId,
  setUploadFile,
} from '../../../redux/reducer/workflow';
import RemoveModal from '../../../components/removeModal';

export default function EditAttachment(props) {
  const { attachmentOpen, setAttachmentOpen } = props;
  const [openDropZone, setOpenDropZone] = useState('false');
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    data, getwFlowData, selectVarId, getFiles, getRemoveFileId,
  } = useSelector((state) => state.workflow);
  const [selectedIds, setSelectedIds] = useState([]);
  const [newFileID, setNewFileId] = useState([]);

  // To get selected row id

  const handleCheckId = (e, id) => {
    if (selectedIds.find((f) => f === id)) {
      setSelectedIds(selectedIds.filter((f) => f !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  // To get selected row id of newly added file in variable pop up
  const handleCheckNewId = (i) => {
    if (newFileID.find((f) => f === i)) {
      setNewFileId([...newFileID.filter((f) => f !== i)]);
    } else {
      setNewFileId([...newFileID, i]);
    }
  };

  // To get uploade file data
  const getUpdatedFile = async () => {
    await ApiGet(`admin/get-workflow-component?diseaseId=${data?._id}`)
      .then((res) => {
        dispatch(sendWorkFlowData(res?.data?.data));
        dispatch(getApiCount(0));
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  useEffect(() => {
    if (attachmentOpen) {
      // If variable pop up section is open
      dispatch(
        setRemoveFileId(
          getRemoveFileId?.length > 0
            ? getRemoveFileId
            : selectVarId?.titleType?.attachments,
        ),
      );
    } else {
      // If workflow component is open
      dispatch(setRemoveFileId(getwFlowData?.disease_data?.[0]?.attachments));
    }
  }, [getwFlowData]);

  // To delete file from pop up

  const removePopAttach = async () => {
    setOpenDropZone('false');

    // To remove attachment from attachment screen only

    if (getRemoveFileId && selectedIds) {
      var filterData = getRemoveFileId?.filter(
        (j) => !selectedIds.includes(j?._id),
      );
    }
    filterData && dispatch(setRemoveFileId(filterData));
    dispatch(sendDeleteId(selectedIds));

    // To remove newly added attachment from attachment screen only

    if (newFileID?.length > 0) {
      var uploadedFile = getFiles?.filter(
        (k, i) => !newFileID.includes(i.toString()),
      );
    }
    uploadedFile && dispatch(setUploadFile(uploadedFile));
  };

  // To delete file from attachment

  const removeAttach = async () => {
    if (!(selectedIds?.length > 0)) {
      toast.error('Please select attachment');
    } else {
      const formData = new FormData();
      {
        selectedIds?.length > 0
          && Array.from(selectedIds).forEach((file) => {
            formData.append('ids', file);
          });
      }
      // dispatch(handleUpdateWorkflow(data?._id , formData)).unwrap().then((res)=>{
      //   console.log('1111res',res);
      //   getUpdatedFile();
      //     toast.success("Removed successfully");
      //     setSelectedIds([]);
      // }).catch((e)=>
      // console.log('eeeeee',e))
      await ApiPatch(
        `admin/update-passport-credentials?id=${data?._id}`,
        formData,
      )
        .then((res) => {
          getUpdatedFile();
          toast.success('Removed successfully');
          setSelectedIds([]);
          setOpenDropZone('false');
          // history.push("/workflow");
        })
        .catch((err) => {
          toast.error('An error occurred.');
          console.log('err', err);
        });
    }
  };

  const handleOpenRemoveModal = () => {
    if (!(selectedIds?.length > 0 || newFileID?.length > 0)) {
      toast.error('Please select attachment');
    } else {
      setOpenDropZone('deleteModal');
    }
  };

  const handleClose = () => {
    setOpenDropZone('false');
  };

  return (
    <>
      <div className="EditAttachment_section">
        <Toaster />
        <div className="EditAttachment_section_head flex">
          <div className="left_block">
            <div
              className="icon_wrapper"
              onClick={() => (attachmentOpen === true
                ? setAttachmentOpen(!attachmentOpen)
                : history.push('/workflow'))
              }
            >
              <img src={EditpopUp} alt="EditpopUp" />
            </div>
            <div className="Copy_btn">
              <img src={Attach} alt="Attach" />
            </div>
            <h2 className="head_text">Attachments</h2>
          </div>
          <div className="right_block">
            <Tooltip title="Delete attachment">
              <div className="delete-btn">
                <img
                  src={Delete}
                  alt="Delete"
                  onClick={() => handleOpenRemoveModal()}
                />
              </div>
            </Tooltip>
            <p onClick={() => setOpenDropZone('true')}> + Upload new file</p>
          </div>
        </div>
        <div className="editAttachment_section_main">
          <div className="attachments_list">
            <div className="attachments_list_head">
              <table>
                <thead>
                  <tr>
                    <th align="left"></th>
                    <th align="left">File name</th>
                    <th align="left">
                      <div className="uploaded-alignment">
                        Uploaded
                        {/* <img src={GreyDropDownIcon} alt="GreyDropDownIcon" /> */}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {getRemoveFileId?.map((f) => (
                      <tr>
                        <td className="small-checkbox">
                          <input
                            type="checkbox"
                            value={f?._id}
                            checked={selectedIds?.find((h) => h === f?._id)}
                            onChange={(e) => handleCheckId(e, f?._id)}
                          />
                        </td>
                        <td>
                          <a
                            href={f?.image}
                            target="_blank"
                            style={{ color: '#000' }} rel="noreferrer"
                          >
                            {f?.fileName}
                          </a>
                        </td>
                        <td>{moment(f?.date).format('MM.DD.YY')}</td>
                      </tr>
                  ))}
                  <>
                    {getFiles?.length > 0
                      && getFiles?.map((h, i) => (
                          <tr key={i}>
                            <td className="small-checkbox">
                              <input
                                type="checkbox"
                                value={i}
                                checked={newFileID?.includes(i.toString())}
                                onChange={() => handleCheckNewId(i.toString())}
                              />
                            </td>
                            <td>
                              <a
                                href={h?.name && URL.createObjectURL(h)}
                                target="_blank"
                                style={{ color: '#000' }} rel="noreferrer"
                              >
                                {h?.name}
                              </a>
                            </td>
                            <td>{moment(new Date()).format('MM.DD.YY')}</td>
                          </tr>
                      ))}
                  </>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {openDropZone === 'true' && (
          <UploadDropzone
            setOpenDropZone={setOpenDropZone}
            openDropZone={openDropZone}
            dataId={data?._id}
            attachmentOpen={attachmentOpen}
          />
        )}
        {openDropZone === 'deleteModal' && (
          <RemoveModal
            onRemove={() => (attachmentOpen === true ? removePopAttach() : removeAttach())
            }
            onClose={() => handleClose()}
            title=" Are you sure you want to delete the attachment ?"
            description="This action can not be undone"
            cancel="Cancel"
            approve="Yes, delete it!"
          />
        )}
      </div>
    </>
  );
}
