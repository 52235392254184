/* eslint-disable react/prop-types */
import React from 'react';

export default function ProfileIcon({
  color, filled,
}) {
  return (
    <>
    {filled
      ? (<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8.00001" cy="7.99994" r="5.81818" fill="#3CB44B"/>
        <path d="M6.477 8.30135C6.2592 8.11316 5.94137 8.1513 5.76712 8.38653C5.59288 8.62177 5.62819 8.96502 5.846 9.1532L7.47735 10.5627L10.5742 6.91403C10.7627 6.69197 10.7488 6.34693 10.5432 6.14337C10.3376 5.93981 10.0181 5.95482 9.82961 6.17688L7.3709 9.07368L6.477 8.30135Z" fill="white"/>
        </svg>)
      : (<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8.49982" cy="7.99994" r="5.31818" stroke={color}/>
        <path d="M6.97682 8.30135C6.75901 8.11316 6.44119 8.1513 6.26694 8.38653C6.09269 8.62177 6.12801 8.96502 6.34582 9.1532L7.97717 10.5627L11.074 6.91403C11.2625 6.69197 11.2486 6.34693 11.043 6.14337C10.8374 5.93981 10.5179 5.95482 10.3294 6.17688L7.87072 9.07368L6.97682 8.30135Z"
        fill={color}/>
        </svg>)
    }
    </>
  );
}
