/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import './credentials.scss';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import toast, { Toaster } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { ApiGet, ApiPost } from '../../helper/helpers/api/apiData';
import { getData } from '../../redux/reducer/credentials';
import Loader from '../../components/loader';
import CloseIcon from '../../assets/icons/file-data-close.svg';
import LinkIcon from '../../assets/icons/link.svg';
import { menuData } from '../../redux/reducer/workflow';

export default function Credentials() {
  const { dataGet } = useSelector((state) => state.credential);
  const [credentialData, setCredentialData] = useState({});
  const [diseaseData, setDiseaseData] = useState([]);
  const [errors, setErrors] = useState('');
  const [value, setValue] = useState(null);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const history = useHistory();

  // To get disease data for dropdown
  const handleDiseaseData = async () => {
    await ApiGet('admin/desease-list')
      .then((res) => {
        setDiseaseData(res?.data?.data);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  // To get previosly added passport credential for submenu data after api calling

  const getPassportCredData = async () => {
    await ApiGet('admin/get-passport-credentials')
      .then((res) => {
        dispatch(getData(res?.data?.data));
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  useEffect(() => {
    handleDiseaseData();
    getPassportCredData();
  }, []);

  // To filter out previously added dicease from dicease dropdown

  // const removeDuplicate = () => {
  //   var tempData = dataGet && dataGet?.map((f) => f.disease);
  //   var returnData =
  //     dataGet && diseaseData?.filter((f) => !tempData.includes(f));
  //   return returnData;
  // };

  // To validate input field of passport credential

  const validateFormForAdd = () => {
    let formIsValid = true;
    const error = {};

    if (credentialData && !credentialData?.title) {
      formIsValid = false;
      error.title = '*Please enter title!';
    }

    if (credentialData && !credentialData?.disease) {
      formIsValid = false;
      error.disease = '*Please select disease!';
    }

    if (!value || value === '') {
      formIsValid = false;
      error.date = '*Please select date!';
    }

    setErrors(error);
    return formIsValid;
  };

  // To remove uploaded file from dropzone

  const handleRemoveFile = (index) => {
    setCredentialData({
      ...credentialData,
      file: credentialData.file.filter((_, i) => i !== index),
    });
  };

  // To set input value

  const handleChange = (e, addMore) => {
    if (e.target.name === 'file') {
      if (addMore) {
        const allFiles = [];
        e.target.files?.length > 0 && allFiles.push(...e.target.files);
        setCredentialData((prev) => ({
          ...prev,
          [e.target.name]: [...prev.file, ...allFiles],
        }));
      } else {
        const allFiles = [];
        e.target.files?.length > 0 && allFiles.push(...e.target.files);
        setCredentialData((prev) => ({ ...prev, [e.target.name]: allFiles }));
      }
    } else {
      setCredentialData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }
    setErrors({ ...errors, [e.target.name]: '' });
  };

  // To send passport credential data to api

  const handleContinue = async () => {
    if (validateFormForAdd()) {
      setLoader(true);
      const formData = new FormData();
      formData.append('title', credentialData?.title);
      formData.append('disease', credentialData?.disease);
      formData.append(
        'effective_date',
        `${moment(new Date(value)).format('YYYY-MM-DDTHH:mm:ss')}Z`,
      );
      {
        credentialData?.file?.length > 0
          && Array.from(credentialData?.file).forEach((file) => {
            formData.append('attachments', file);
          });
      }
      await ApiPost('admin/add-passport-credentials', formData)
        .then((res) => {
          dispatch(menuData(res?.data?.data));
          history.push('/workflow');
          toast.success('Submitted successfully');
          setCredentialData({ disease: '', file: '', title: '' });
          setValue(null);
          setLoader(false);
          getPassportCredData();
        })
        .catch((err) => {
          toast.error('An error occurred.');
          console.log('err', err);
        });
    }
  };
  // Remove text field input from datepicker

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  return (
    <div className="cred-align">
      <Toaster />
      <div className="home-page-contnet-alignmnet">
        <div className="box-title">
          <span>New set of credentials</span>
        </div>

        <div className="form form-bottom-align">
          <div className="label-error-message-alignment">
            <label>Title</label>
            <span>*</span>
          </div>
          <input placeholder="Give your set a title" name="title" value={credentialData?.title} onChange={(e) => handleChange(e)}/>
          <span
            style={{
              color: 'red',
              top: '5px',
              fontSize: '12px',
            }}
          >
            {errors.title}
          </span>
        </div>

        <div className="form form-bottom-align">
          <div className="label-error-message-alignment">
            <label>Disease</label>
            <span>*</span>
          </div>
          <select id="disease" name="disease" onClick={(e) => handleChange(e)}>
            <option
            className="select-disease"
              value=""
              selected={credentialData?.disease === ''}
            >
              Select Disease
            </option>
            {diseaseData?.map((data) => (
                  <>
                    <option value={data}>{data}</option>
                  </>
            ))}
          </select>
          <span
            style={{
              color: 'red',
              top: '5px',
              fontSize: '12px',
            }}
          >
            {errors.disease}
          </span>
        </div>
        <div className=" form-bottom-align">
          <div
            className="label-error-message-alignment"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <label style={{ width: '100%' }}>Effective date</label>
            <span style={{ color: 'red' }}>*</span>
          </div>
          <div className="datepicker-style">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Select from the calander"
                value={value}
                disablePast
                onChange={(newValue, e) => {
                  setValue(new Date(newValue));
                  setErrors({ ...errors, date: '' });
                }}
                renderInput={(params) => (
                  <TextField {...params} onKeyDown={onKeyDown} />
                )}
              />
            </LocalizationProvider>
          </div>
          <span
            style={{
              color: 'red',
              top: '5px',
              fontSize: '12px',
            }}
          >
            {errors.date}
          </span>
        </div>
        <div className="attachment form">
          <div className="label-error-message-alignment">
            <label>Attachment</label>
          </div>
          <div className="file-upload">
            {!(credentialData?.file?.length > 0) ? (
              <div className="file-upload-text-style">
                <img src={LinkIcon} alt="LinkIcon" />
                <span>Drag and drop or click to upload</span>
                <input
                  type="file"
                  name="file"
                  onChange={(e) => handleChange(e)}
                  multiple
                  accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                />
              </div>
            ) : (
              <div className="file-upload-all-show-text-alignment">
                {credentialData?.file
                  && credentialData?.file?.map((data, i) => (
                    <button key={i}>
                      <a>{data?.name}</a>
                      <img
                        src={CloseIcon}
                        onClick={() => handleRemoveFile(i)}
                        alt="CloseIcon"
                      />
                    </button>
                  ))}
              </div>
            )}

            {credentialData?.file?.length > 0 && (
              <div className="more-file-input">
                Add more
                <input
                  type="file"
                  name="file"
                  onChange={(e) => handleChange(e, true)}
                  multiple
                  accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                />
              </div>
            )}
          </div>
        </div>

        {loader ? (
          <div className="continue-button">
            <button>
              <Loader />
            </button>
          </div>
        ) : (
          <div className="continue-button">
            <button onClick={(e) => handleContinue(e)}>Continue</button>
          </div>
        )}
      </div>
    </div>
  );
}
