/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import Header from '../components/header';
import Sidebar from '../components/sidebar';
import AuthenticationCode from './authenticationCode';
import AuthenticationSection from './authenticationSection';
import ChangePassword from './changePassword';
import CreateProfile from './createProfile';
import Credentials from './credentials';
import EditAttachment from './credentials/editAttachment';
import Credworkflow from './credworkflow';
import VerifyAccount from './verifyAccount';

export default function ProtectedRoute() {
  return (
    <>
      <BrowserRouter>
      <Switch>
      <RouteWrapper
          exact={true}
          path="/home"
          component={Credentials}
          layout={DefaultLayout}
        />
        <RouteWrapper
          exact={true}
          path="/credentials"
          component={Credentials}
          layout={DefaultLayout}
        />
        <RouteWrapper
          exact={true}
          path="/workflow"
          component={Credworkflow}
          layout={DefaultLayout}
        />
        <RouteWrapper
          exact={true}
          path="/workflow/:id"
          component={Credworkflow}
          layout={DefaultLayout}
        />
         <RouteWrapper
          exact={true}
          path="/editattachment"
          component={EditAttachment}
          layout={DefaultLayout}
        />
         <RouteWrapper
          exact={true}
          path="/2fa"
          component={AuthenticationSection}
          layout={LoginLayout}
        />
        <RouteWrapper
          exact={true}
          path="/change-password/:email"
          component={ChangePassword}
          layout={LoginLayout}
        />
        <RouteWrapper
          exact={true}
          path="/verifyEmail"
          component={VerifyAccount}
          layout={LoginLayout}
        />
        <RouteWrapper
          exact={true}
          path="/2fa"
          component={AuthenticationSection}
          layout={LoginLayout}
        />
        <RouteWrapper
          exact={true}
          path="/register"
          component={CreateProfile}
          layout={LoginLayout}
        />
        <RouteWrapper
          exact={true}
          path="/authentication-code"
          component={AuthenticationCode}
          layout={LoginLayout}
        />
        </Switch>
      </BrowserRouter>
    </>
  );
}
const LoginLayout = ({ children, match }) => <> <div style={{ backgroundColor: '#F7F7F7' }}>{children}</div></>;

const DefaultLayout = ({ children, match }) => {
  const [sidebarClose, setSidebarClose] = useState(false);

  return (
  <>
  <Header />
  <div className="auth-layout">
    <div className="auth-layout-items">
      <Sidebar setSidebarClose={setSidebarClose} sidebarClose={sidebarClose} />
    </div>
    <div className="auth-layout-items">
      <div className={sidebarClose === true ? 'auth-layout-left-right-content-alignment ' : 'auth-layout-left-right-content-alignment p-276'}>
        {children}
        </div>
      </div>
  </div>
</>
  );
};
function RouteWrapper({
  component: Component, layout: Layout, auth, ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}
