/* eslint-disable no-underscore-dangle */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import useDebounce from '../../../helper/constant/usedeBounceHook';
import { ApiGet, ApiPatch } from '../../../helper/helpers/api/apiData';
import DotIcon from '../../../assets/icons/dot-icon.svg';
import './workflowHeader.scss';
import { getData } from '../../../redux/reducer/credentials';

export default function WorkflowHeader() {
  const { data, apiCount, getwFlowData } = useSelector(
    (state) => state.workflow,
  );
  const [diseaseTitle, setDiseaseTitle] = useState(
    getwFlowData?.disease_data?.[0]?.title ? getwFlowData?.disease_data?.[0]?.title : '',
  );
  const dispatch = useDispatch();
  const [callDebouce, setCallDebounce] = useState('');
  const debouncedDiseaseTitle = useDebounce(diseaseTitle, 1000);

  useEffect(() => {
    setDiseaseTitle(getwFlowData?.disease_data?.[0]?.title);
  }, [getwFlowData]);

  console.log('getwFlowDatagetwFlowData', getwFlowData);

  useEffect(() => {
    if (callDebouce === 'changeTitle') {
      handleUpdateDiseaseTitle();
    }
  }, [debouncedDiseaseTitle]);

  const handleChangeDisease = (e, changeTitle) => {
    if (changeTitle) {
      setDiseaseTitle(e.target.value);
      setCallDebounce('changeTitle');
    }
  };

  const handleUpdateDiseaseTitle = async () => {
    if (data?._id) {
      const formData = new FormData();
      formData.append(
        'title',
        debouncedDiseaseTitle || '',
      );
      await ApiPatch(
        `admin/update-passport-credentials?id=${data?._id}`,
        formData,
      )
        .then((res) => {
          setDiseaseTitle(res?.data?.data?.title);
          getUpdatedMenu();
          setCallDebounce('');
          toast.success('Title updated');
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  };

  const getUpdatedMenu = async () => {
    await ApiGet('admin/get-passport-credentials')
      .then((res) => {
        dispatch(getData(res?.data?.data));
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  return (
    <>
      <div className="work-flow-header-content-alignment" key={data?._id}>
        <div className="left-side-content headerInputAlignment">
          <div className="work-flow-title">
            <input
              type="text"
              // placeholder={data?.title ? data?.title : data?.disease}
              value={diseaseTitle}
              onChange={(e) => handleChangeDisease(e, 'changeTitle')}
            />
            {/* {getwFlowData?.disease_data?.disease} */}
            <div className="right-side-content">
              <div className="draft-text">
                <span></span>
                <a>Draft</a>
              </div>
              <div className="publish-button">
                <button>Publish</button>
              </div>
            </div>
          </div>
          <div className="left-content-allignment">
            <div>
              {getwFlowData?.disease_data?.[0]?.disease} requirement
              <img src={DotIcon} alt="DotIcon" className="dot-icon" />
              Created on:
              {moment(getwFlowData?.disease_data?.[0]?.created_on).format(
                'DD.MM.YYYY',
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
