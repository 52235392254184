/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable no-bitwise */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import {
  Redirect,
  Switch,
  Route,
  BrowserRouter,
  useHistory,
} from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import Auth from '../helper/helpers/auth';
import AuthRoutes from './authRoutes';
import ProtectedRoute from './protectedRoute';
import { ApiPostNoAuth } from '../helper/helpers/api/apiData';

export default function Routes() {
  const [checkLog, setcheckLog] = useState(false);
  const history = useHistory();
  const userInfo = JSON.parse(localStorage.getItem('user'));

  const handleLogout = () => {
    localStorage.removeItem('qrcode');
    localStorage.removeItem('x-access-token');
    localStorage.removeItem('user');
    history.push('/login');
    window.location.reload();
  };

  let secondsSinceLastActivity = 0;
  const tokenExpTime = userInfo?.token ? jwtDecode(userInfo?.token) : '';
  const expTime = 10;

  useEffect(() => {
    if (userInfo && !checkLog) {
      const date = setInterval(() => {
        secondsSinceLastActivity++;
        const timeStemp = tokenExpTime.exp * 1000 - new Date().getTime();
        const TimeDifferent = Math.floor((timeStemp / 1000 / 60) << 0);
        if (TimeDifferent > expTime) {
          setcheckLog(false);
        } else if (TimeDifferent < expTime) {
          setcheckLog(true);
          handleLogout();
        }
        if (secondsSinceLastActivity < 10 && TimeDifferent === expTime) {
          handleLogin();
        } else if (secondsSinceLastActivity > 10 && TimeDifferent === expTime) {
          setcheckLog(true);
          handleLogout();
        }
      }, 1000);
      return () => {
        clearInterval(date);
      };
    }
  }, [secondsSinceLastActivity, userInfo]);

  function activity() {
    secondsSinceLastActivity = 0;
  }
  const activityEvents = [
    'mousedown',
    'mousemove',
    'keydown',
    'keypress',
    'scroll',
    'touchstart',
  ];
  activityEvents.forEach((eventName) => {
    document.addEventListener(eventName, activity, true);
  });

  const handleLogin = async () => {
    setcheckLog(true);
    const body = {
      refreshToken: userInfo.refreshToken,
    };
    await ApiPostNoAuth('user/get-admin-access-token', body)
      .then((res) => {
        setcheckLog(false);
        localStorage.setItem('user', JSON.stringify(res?.data?.message));
        localStorage.setItem(
          'x-access-token',
          JSON.stringify(res?.data?.message?.token),
        );
      })
      .catch((err) => {
        setcheckLog(true);
        handleLogout();
      });
  };

  return (
    <>
      <Route>
        <AuthRoutes />
      </Route>
      {Auth.isUserAuthenticated() && (
        <>
          <ProtectedRoute />
        </>
      )}
    </>
  );
}
