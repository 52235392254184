import React, { useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { ApiPostNoAuth } from '../../helper/helpers/api/apiData';
import './login.scss';

export default function Login() {
  const [loginValue, setLoginValue] = useState({ organizationname: '', email: '', password: '' });
  const [errors, setErrors] = useState('');
  const regexEmail = /^(([^<>()[\],;:\s@]+([^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+)+[^<>()[\],;:\s@]{2,})$/i;
  const history = useHistory();
  const handleSignup = () => {
    history.push('/signup');
  };

  const validateForm = () => {
    let formIsValid = true;
    const error = {};
    if (loginValue && !loginValue?.email?.trim()) {
      formIsValid = false;
      error.email = '*Please enter email!';
    } else if (loginValue && !loginValue?.email?.match(regexEmail)) {
      formIsValid = false;
      error.email = '*Please enter valid email!';
    }
    if (loginValue && !loginValue?.password?.trim()) {
      formIsValid = false;
      error.password = '*Please enter password!';
    } else if (loginValue && loginValue?.password?.length < 8) {
      formIsValid = false;
      error.password = '*password should be minimum 8 character long!';
    }
    setErrors(error);
    return formIsValid;
  };

  const handleChange = (e) => {
    setLoginValue((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const handleLogin = async () => {
    if (validateForm()) {
      const body = {
        email: loginValue.email,
        password: loginValue.password,
      };
      await ApiPostNoAuth('admin/admin_login', body)
        .then((res) => {
          if (res.data.isEnable2FA) {
            localStorage.setItem('user', JSON.stringify(res?.data));
            localStorage.setItem('x-access-token', JSON.stringify(res?.data?.token));
            toast.success('Login successfully');
            history.push('/authentication-code');
          } else {
            localStorage.setItem('user', JSON.stringify(res?.data));
            localStorage.setItem('x-access-token', JSON.stringify(res?.data?.token));
            toast.success('Login successfully');
            history.push('/credentials');
            window.location.reload();
          }
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  return (
        <>
        <Toaster />
            <div className="login-all-contnet-alignment">
                <div className="login-box">
                    <h4>Welcome back</h4>
                    <div className="all-input-contnet-alignment">
                        <div className="main-input-content">
                            <div className="label-content-alignment">
                                <label>Email</label>
                                <span>*</span>
                            </div>
                            <div className="input-design">
                                <input type="text" placeholder="Enter your email"
                                 name="email" value={loginValue?.email} onChange={(e) => handleChange(e)}/>
                            </div>
                            <span
                                style={{
                                  color: 'red',
                                  top: '5px',
                                  fontSize: '12px',
                                }}
                            >
                                {errors.email}
                            </span>
                        </div>
                        <div className="main-input-content">
                            <div className="label-content-alignment">
                                <label>Password</label>
                                <span>*</span>
                            </div>
                            <div className="input-design">
                                <input type="password" placeholder="Enter your password"
                                 name="password" value={loginValue?.password} onChange={(e) => handleChange(e)}/>
                            </div>
                            <span
                                style={{
                                  color: 'red',
                                  top: '5px',
                                  fontSize: '12px',
                                }}
                            >
                                {errors.password}
                            </span>
                        </div>
                    </div>
                    <div className="forgot-password-alignment">
                        <span onClick={() => history.push('/forgotpassword')}>Forgot your password?</span>
                    </div>
                    <div className="login-button-center-alignment">
                        <button onClick={handleLogin}>Login</button>
                    </div>
                    <div className="dont-have-account">
                        <p>Don’t have an account? <a onClick={handleSignup}>Signup</a></p>
                    </div>
                </div>
            </div>
        </>
  );
}
