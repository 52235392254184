/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { ApiPostNoAuth } from '../../helper/helpers/api/apiData';
import './changePassword.scss';

export default function ChangePassword() {
  const { email } = useParams();
  const [resetValue, setResetValue] = useState({});
  const [errors, setErrors] = useState('');
  const history = useHistory();

  const validateForm = () => {
    let formIsValid = true;
    const error = {};
    if (resetValue && !resetValue?.password?.trim()) {
      formIsValid = false;
      error.password = '*Please enter password!';
    } else if (resetValue && resetValue?.password?.length < 8) {
      formIsValid = false;
      error.password = '*password should be minimum 8 character long!';
    }
    setErrors(error);
    return formIsValid;
  };

  const handleChange = (e) => {
    setResetValue((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const handleResetPassword = async () => {
    if (email && validateForm()) {
      const body = {
        email,
        password: resetValue.password,
      };
      await ApiPostNoAuth('admin/resetadminpassword', body)
        .then((res) => {
          toast.success('Password reset successfully');
          history.push('/login');
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };
  return (
    <>
      <Toaster />
      <div className="change-password-all-content-alignment">
        <div className="change-password-box">
          <h1>Change password</h1>
          <div className="all-input-contnet-alignment">
            <div className="main-input-content">
              <div className="label-content-alignment">
                <label>Email</label>
                <span>*</span>
              </div>
              <div className="input-design">
                <input value={email} type="text" disabled={true} />
              </div>
            </div>
            <div className="main-input-content">
              <div className="label-content-alignment">
                <label>New Password</label>
                <span>*</span>
              </div>
              <div className="input-design">
                <input
                  type="password"
                  placeholder="Create a password"
                  name="password"
                  value={resetValue?.password}
                  onChange={(e) => handleChange(e)}
                />
                <span
                  style={{
                    color: 'red',
                    top: '5px',
                    fontSize: '12px',
                  }}
                >
                  {errors.password}
                </span>
              </div>
              <div className="counter-text">
                <p>At least 8 characters</p>
              </div>
            </div>
          </div>
          <div className="login-button-center-alignment">
            <button onClick={handleResetPassword}>Confirm</button>
          </div>
        </div>
      </div>
    </>
  );
}
