/* eslint-disable max-len */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-shadow */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import './uploadDropzone.scss';
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import LinkIcon from '../../../assets/icons/link.svg';
import CloseIcon from '../../../assets/icons/file-data-close.svg';
import { ApiPatch, ApiGet } from '../../../helper/helpers/api/apiData';
import useOnClickOutside from '../../../helper/constant/outsideClick';
import { sendWorkFlowData, getApiCount, setUploadFile } from '../../../redux/reducer/workflow';
import Loader from '../../../components/loader';

export default function UploadDropzone(props) {
  const [loader, setLoader] = useState(false);
  const {
    setOpenDropZone, openDropZone, dataId, attachmentOpen,
  } = props;
  const [uploadFileData, setUploadFileData] = useState({});
  const outsideRef = useRef(null);
  const dispatch = useDispatch();
  const { data, getFiles } = useSelector((state) => state.workflow);
  const pathName = window.location.pathname;

  const onCancel = () => {
    setOpenDropZone(!openDropZone);
  };

  useOnClickOutside(outsideRef, onCancel);

  const handleDeleteFile = (index) => {
    setUploadFileData({
      ...uploadFileData,
      file: uploadFileData.file.filter((_, i) => i !== index),
    });
  };

  // To get uploade file data

  const getUpdatedFile = async () => {
    if (!attachmentOpen) {
      await ApiGet(`admin/get-workflow-component?diseaseId=${data?._id}`)
        .then((res) => {
          dispatch(sendWorkFlowData(res?.data?.data));
          dispatch(getApiCount(0));
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  };

  const handleChangeFile = (e, addMore) => {
    if (e.target.name === 'file') {
      if (addMore) {
        const allFiles = [];
        e.target.files?.length > 0 && allFiles.push(...e.target.files);
        setUploadFileData((prev) => ({
          ...prev,
          [e.target.name]: [...prev.file, ...allFiles],
        }));
      } else {
        const allFiles = [];
        e.target.files?.length > 0 && allFiles.push(...e.target.files);
        setUploadFileData({ ...uploadFileData, [e.target.name]: allFiles });
      }
    } else {
      setUploadFileData({ ...uploadFileData, [e.target.name]: e.target.value });
    }
  };

  // To set file data in variable pop up
  const handleFilePopUp = () => {
    const fileArray = uploadFileData?.file;
    getFiles?.length > 0 && fileArray.push(...getFiles);
    dispatch(setUploadFile(fileArray));
    setUploadFileData({});
    setOpenDropZone(!openDropZone);
    getUpdatedFile();
    setLoader(false);
  };
  // To send passport credential data to api

  const handleFilePublish = async () => {
    if (pathName === '/editattachment') {
      setLoader(true);
      const formData = new FormData();
      {
        uploadFileData?.file?.length > 0
          && Array.from(uploadFileData?.file).forEach((file) => {
            formData.append('attachments', file);
          });
      }
      await ApiPatch(`admin/update-passport-credentials?id=${dataId}`, formData)
        .then((res) => {
          toast.success('Submitted successfully');
          setUploadFileData({});
          setOpenDropZone(!openDropZone);
          getUpdatedFile();
          setLoader(false);
        })
        .catch((err) => {
          toast.error('An error occurred.');
          console.log('err', err);
        });
    }
  };

  return (
    <>
      <Toaster />
      <div className="uploadDropzone-modal">
        <div className="file-upload" ref={outsideRef}>
          <div>
            {!(uploadFileData?.file?.length > 0) ? (
              <div className="file-upload-text-style">
                <img src={LinkIcon} alt="LinkIcon" />
                <span>Drag and drop or click to upload</span>
                <input
                  type="file"
                  name="file"
                  onChange={(e) => handleChangeFile(e)}
                  multiple
                  accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                />
              </div>
            ) : (
              <div className="file-upload-all-show-text-alignment">
                {uploadFileData?.file
                  && uploadFileData?.file?.map((data, i) => (
                    <button key={i}>
                      <a>{data?.name}</a>
                      <img
                        src={CloseIcon}
                        onClick={() => handleDeleteFile(i)}
                        alt="CloseIcon"
                      />
                    </button>
                  ))}
              </div>
            )}
            {uploadFileData?.file?.length > 0 && (
              <div className="more-file-input">
                Add more
                <input
                  type="file"
                  name="file"
                  onChange={(e) => handleChangeFile(e, true)}
                  multiple
                  accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                />
              </div>
            )}
            {loader ? (
              <div align="center">
                <div className="save-button-alignment">
                  <button>
                    <Loader />
                  </button>
                </div>
              </div>
            ) : (
              <div align="center">
                <div className="save-button-alignment">
                  <button onClick={() => (attachmentOpen === true ? handleFilePopUp() : handleFilePublish())}>Save</button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
