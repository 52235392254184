/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import './signupPage.scss';
import { toast, Toaster } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ConfirmationIcon from '../../assets/icons/Confirmation.svg';
import { ApiPostNoAuth } from '../../helper/helpers/api/apiData';
import ProfileIcon from '../../helper/iconColor/porofileIcon';
import Authentication from '../../assets/icons/authentication.svg';
import { setLoginData } from '../../redux/reducer/workflow';

export default function SignupPage() {
  const [signupValue, setSignupValue] = useState({ organizationname: '', email: '', password: '' });
  const [errors, setErrors] = useState('');
  const history = useHistory();
  const regexEmail = /^(([^<>()[\],;:\s@]+([^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+)+[^<>()[\],;:\s@]{2,})$/i;
  const dispatch = useDispatch();

  const validateForm = () => {
    let formIsValid = true;
    const error = {};
    if (signupValue && !signupValue?.organizationname?.trim()) {
      formIsValid = false;
      error.organizationname = '*Please enter organization name!';
    }
    if (signupValue && !signupValue?.email?.trim()) {
      formIsValid = false;
      error.email = '*Please enter email!';
    } else if (signupValue && !signupValue?.email?.match(regexEmail)) {
      formIsValid = false;
      error.email = '*Please enter valid email!';
    }
    if (signupValue && !signupValue?.password?.trim()) {
      formIsValid = false;
      error.password = '*Please enter password!';
    } else if (signupValue && signupValue?.password?.length < 8) {
      formIsValid = false;
      error.password = '*password should be minimum 8 character long!';
    }
    setErrors(error);
    return formIsValid;
  };

  const handleChange = (e) => {
    setSignupValue((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const handleContinue = async () => {
    if (validateForm()) {
      const body = {
        email: signupValue.email,
        organization: signupValue.organizationname,
        password: signupValue.password,
      };
      dispatch(setLoginData(body));
      await ApiPostNoAuth('admin/add-admin', body)
        .then((res) => {
          toast.success(res?.data?.message);
          history.push('/verifyEmail');
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };
  return (
        <>  <Toaster />
            <div className="signup-page-all-content-alignment">
                <div className="signup-box">
                    <div className="text-style">
                        <h4>Create account</h4>
                    </div>
                    <div className="icon-text-all-content-alignment">
                        <div className="icon-text">
                            <div>
                                <ProfileIcon color="#3CB44B"/>
                            </div>
                            <div>
                                <span>CREATE ACCOUNT</span>
                            </div>
                        </div>
                        <div className="icon-text">
                            <div>
                            <ProfileIcon color="#76767E"/>
                            </div>
                            <div>
                                <span>CREATE PROFILE</span>
                            </div>
                        </div>
                        <div className="icon-text">
                            <div>
                                <img src={Authentication} alt="Authentication"/>
                            </div>
                            <div>
                                <span>ENABLE 2FA</span>
                            </div>
                        </div>
                    </div>
                    <div className="all-input-contnet-alignment">
                    <div className="main-input-content">
                            <div className="label-content-alignment">
                                <label>Organization</label>
                                <span>*</span>
                            </div>
                            <div className="input-design">
                            <input placeholder="Enter your organization name"
                                name="organizationname" value={signupValue?.organizationname} onChange={(e) => handleChange(e)}
                                />
                            <span
                                style={{
                                  color: 'red',
                                  top: '5px',
                                  fontSize: '12px',
                                }}
                            >
                                {errors.organizationname}
                            </span>
                            </div>
                        </div>
                        <div className="main-input-content">
                            <div className="label-content-alignment">
                                <label>Email</label>
                                <span>*</span>
                            </div>
                            <div className="input-design">
                            <input placeholder="Enter your email"
                                name="email" value={signupValue?.email} onChange={(e) => handleChange(e)} />
                                <span
                                style={{
                                  color: 'red',
                                  top: '5px',
                                  fontSize: '12px',
                                }}
                                >
                                {errors.email}
                                </span>
                            </div>
                        </div>
                        <div className="main-input-content">
                            <div className="label-content-alignment">
                                <label>Password</label>
                                <span>*</span>
                            </div>
                            <div className="input-design">
                            <input
                            placeholder="create a password"
                            type="password"
                            name="password" value={signupValue?.password} onChange={(e) => handleChange(e)}
                            />
                            <span
                            style={{
                              color: 'red',
                              top: '5px',
                              fontSize: '12px',
                            }}
                            >
                                {errors.password}
                            </span>
                            </div>
                        </div>
                    </div>
                    <div className="text-limit-style">
                        <span>At least 8 characters</span>
                    </div>
                    <div className="login-button-center-alignment">
                        <button onClick={handleContinue}>Next</button>
                    </div>
                    <div className="last-text-style">
                        <p>By signing up you accept <a>terms and privacy policy </a></p>
                    </div>
                    <div className="dont-have-account">
                        <p>Already have an account? <a onClick= {() => history.push('/login')}>Login</a></p>
                    </div>
                </div>
            </div>
        </>
  );
}
