/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { Toaster } from 'react-hot-toast';
import '../subInputModal/subInputModal.scss';
import { useSelector } from 'react-redux';
import RightIcon from '../../../../../assets/icons/right-arrow-md.svg';

export default function ImmunityInputModal(props) {
  const {
    rowData, onClose, handleOnChangeData, i,
  } = props;
  const { tableData } = useSelector((state) => state.workflow);

  // to set input data value
  const handleInput = (e) => {
    const { name, value } = e.target;
    handleOnChangeData(i, name, value);
  };

  return (
    <>
      <Toaster />
      <div className="international-modal-wrapper-xl">
        <div className="inernational-md">
          <div className="inernational-md-modal-alignment">
            <div className="inernational-md-header">
              <div className="left-alignment">
              <div onClick={onClose} ><img src={RightIcon} alt="RightIcon" /></div>
                <input
                  type="text"
                  placeholder={tableData?.title?.[0]}
                  id="vaccine"
                  name="vaccine"
                  value={rowData?.vaccine}
                  onChange={(e) => handleInput(e)}
                />
              </div>
            </div>
            <div className="inernational-body-all-content-alignment">
              <div className="comming-form-box">{tableData?.title?.[1]}</div>
              <div className="country-input">
                <input
                  type="text"
                  placeholder={tableData?.title?.[1]}
                  name="minDoses"
                  id="minDoses"
                  value={rowData?.minDoses}
                  onChange={(e) => handleInput(e)}
                />
              </div>
              <div className="travelling-box">{tableData?.title?.[2]} </div>
              <div className="country-input">
                <input
                  type="text"
                  placeholder={tableData?.title?.[2]}
                  name="minDays"
                  id="minDays"
                  value={rowData?.minDays}
                  onChange={(e) => handleInput(e)}
                />
              </div>
              <div className="travelling-box"> {tableData?.title?.[3]} </div>
              <div className="country-input">
                <input
                  type="text"
                  placeholder={tableData?.title?.[3]}
                  name="Validfor"
                  id="Validfor"
                  value={rowData?.Validfor}
                  onChange={(e) => handleInput(e)}
                />
              </div>
              <div className="travelling-box"> {tableData?.title?.[4]} </div>
              <div className="country-input">
                <input
                  type="text"
                  placeholder={tableData?.title?.[4]}
                  name="datasource"
                  id="datasource"
                  value={rowData?.datasource}
                  onChange={(e) => handleInput(e)}
                />
              </div>
              {/* <div className="travelling-box"> {tableData?.title?.[5]} </div>
              <div className="country-input">
                <input
                  type="text"
                  placeholder={tableData?.title?.[5]}
                  name="comments"
                  id="comments"
                  value={rowData?.comments}
                  onChange={(e) => handleInput(e)}
                />
              </div> */}

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
