/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-prototype-builtins */
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { API } from '../../config/api.config';
import * as authUtil from '../../utils/auth.util';

export const BaseURL = API.hostUrl;

const defaultHeaders = {
  isAuth: true,
  AdditionalParams: {},
  isJsonRequest: true,
};

export const ApiPostNoAuth = (type, userData) => new Promise((resolve, reject) => {
  axios.post(
    BaseURL + type,
    userData,
    userData instanceof FormData === true
      ? getHttpOptionsFormdata({
        ...defaultHeaders,
        isAuth: false,
      })
      : getHttpOptions({
        ...defaultHeaders,
        isAuth: false,
      }),
  )
    .then((responseJson) => {
      resolve(responseJson);
    })
    .catch((error) => {
      if (
        error?.hasOwnProperty('response')
        && error?.response
        && error?.response?.hasOwnProperty('data')
        && error?.response?.data
        && error?.response?.data?.hasOwnProperty('error')
        && error?.response?.data?.error
      ) {
        reject(error?.response?.data?.error);
      } else {
        reject(error?.response?.data?.message);
      }
    });
});

export const ApiPutNoAuth = (type, userData) => new Promise((resolve, reject) => {
  axios
    .put(
      BaseURL + type,
      userData,
      getHttpOptions({
        ...defaultHeaders,
        isAuth: false,
      }),
    )
    .then((responseJson) => {
      resolve(responseJson);
    })
    .catch((error) => {
      if (
        error?.hasOwnProperty('response')
        && error?.response
        && error?.response?.hasOwnProperty('data')
        && error?.response?.data
        && error?.response?.data?.hasOwnProperty('error')
        && error?.response?.data?.error
      ) {
        reject(error?.response?.data?.error?.message);
      } else {
        reject(error?.response?.data?.message);
      }
    });
});

export const ApiGetNoAuth = (type) => new Promise((resolve, reject) => {
  axios
    .get(
      BaseURL + type,
      getHttpOptions({
        ...defaultHeaders,
        isAuth: false,
      }),
    )
    .then((responseJson) => {
      resolve(responseJson);
    })
    .catch((error) => {
      if (
        error?.hasOwnProperty('response')
        && error?.response
        && error?.response?.hasOwnProperty('data')
        && error?.response?.data
        && error?.response?.data?.hasOwnProperty('error')
        && error?.response?.data?.error
      ) {
        reject(error?.response?.data?.error?.message);
      } else {
        reject(error?.response?.data?.message);
      }
    });
});

export const FakeApi = (type) => new Promise((resolve, reject) => {
  axios
    .get(
      type,
      getHttpOptions({
        ...defaultHeaders,
        isAuth: false,
      }),
    )
    .then((responseJson) => {
      resolve(responseJson);
    })
    .catch((error) => {
      if (
        error?.hasOwnProperty('response')
        && error?.response
        && error?.response?.hasOwnProperty('data')
        && error?.response?.data
        && error?.response?.data?.hasOwnProperty('error')
        && error?.response?.data?.error
      ) {
        reject(error?.response?.data?.error?.message);
      } else {
        reject(error?.response?.data?.message);
      }
    });
});

export const Api = (type, methodtype, userData) => new Promise((resolve, reject) => {
  userData = userData || {};
  axios({
    url: BaseURL + type,
    headers: getHttpOptions(),
    data: userData,
    type: methodtype,
  })
    .then((responseJson) => {
      resolve(responseJson);
    })
    .catch((error) => {
      if (
        error?.hasOwnProperty('response')
        && error?.response
        && error?.response?.hasOwnProperty('data')
        && error?.response?.data
        && error?.response?.data?.hasOwnProperty('error')
        && error?.response?.data?.error
      ) {
        reject(error?.response?.data?.error?.message);
      } else {
        reject(error?.response?.data?.message);
      }
    });
});

export const ApiGet = (type) => new Promise((resolve, reject) => {
  axios
    .get(BaseURL + type, getHttpOptions())
    .then((responseJson) => {
      resolve(responseJson);
    })
    .catch((error) => {
      if (
        error?.hasOwnProperty('response')
        && error?.response
        && error?.response?.hasOwnProperty('data')
        && error?.response?.data
        && error?.response?.data?.hasOwnProperty('error')
        && error?.response?.data?.error
      ) {
        reject(error?.response?.data?.error?.message);
      } else {
        reject(error?.response?.data?.message);
      }
    });
});

export const ApiPost = (type, userData) => new Promise((resolve, reject) => {
  axios
    .post(
      BaseURL + type,
      userData,
      userData instanceof FormData === true
        ? getHttpOptionsFormdata()
        : getHttpOptions(),
    )
    .then((responseJson) => {
      resolve(responseJson);
    })
    .catch((error) => {
      console.log('api post', error);
      if (error.response.status == 401) {
        console.log('api post 401', error.response.status);
        // Auth.Logout();
        // window.location.reload();
        return console.log(error) + <Redirect to="/login" />;
      }
      if (
        error?.hasOwnProperty('response')
        && error?.response
        && error?.response?.hasOwnProperty('data')
        && error?.response?.data
        && error?.response?.data?.hasOwnProperty('error')
        && error?.response?.data?.error
      ) {
        reject(error?.response?.data?.error?.message);
      } else {
        reject(error?.response?.data?.message);
      }
    });
});

export const ApiPut = (type, userData) => new Promise((resolve, reject) => {
  axios
    .put(
      BaseURL + type,
      userData,
      userData instanceof FormData === true
        ? getHttpOptionsFormdata()
        : getHttpOptions(),
    )
    .then((responseJson) => {
      resolve(responseJson);
    })
    .catch((error) => {
      console.log('api put', error);
      if (error.response.status == 401) {
        console.log('api put 401', error.response.status);
        // Auth.Logout();
        // window.location.reload();
        return console.log(error) + <Redirect to="/login" />;
      }
      if (
        error?.hasOwnProperty('response')
        && error?.response
        && error?.response?.hasOwnProperty('data')
        && error?.response?.data
        && error?.response?.data?.hasOwnProperty('error')
        && error?.response?.data?.error
      ) {
        reject(error?.response?.data?.error);
      } else {
        reject(error?.response?.data?.error);
      }
    });
});

export const ApiPatch = (type, userData) => new Promise((resolve, reject) => {
  axios
    .patch(
      BaseURL + type,
      userData,
      userData instanceof FormData === true
        ? getHttpOptionsFormdata()
        : getHttpOptions(),
    )
    .then((responseJson) => {
      resolve(responseJson);
    })
    .catch((error) => {
      if (
        error?.hasOwnProperty('response')
        && error?.response
        && error?.response?.hasOwnProperty('data')
        && error?.response?.data
        && error?.response?.data?.hasOwnProperty('error')
        && error?.response?.data?.error
      ) {
        reject(error?.response?.data?.error?.message);
      } else {
        reject(error?.response?.data?.message);
      }
    });
});

// export const ApiDelete = (type, userData) => {
//   console.log("UserData121212`21211", userData);
//   return new Promise((resolve, reject) => {
//     axios
//       .delete(BaseURL + type, userData, getHttpOptions())
//       .then((responseJson) => {
//         resolve(responseJson);
//       })
//       .catch((error) => {
//         if (
//           error &&
//           error.hasOwnProperty("response") &&
//           error.response &&
//           error.response.hasOwnProperty("data") &&
//           error.response.data &&
//           error.response.data.hasOwnProperty("error") &&
//           error.response.data.error
//         ) {
//           reject(error?.response?.data?.error?.message);
//         } else {
//           reject(error?.response?.data?.message);
//         }
//       });
//   });
// };

export const ApiDelete = (type, userData) => new Promise((resolve, reject) => {
  axios
    .delete(BaseURL + type, getHttpOptions())
    .then((responseJson) => {
      resolve(responseJson);
    })
    .catch((error) => {
      console.log('api delete', error);
      if (error.response.status == 401) {
        console.log('api put 401', error.response.status);
        // Auth.Logout();
        // window.location.reload();
        return console.log(error) + <Redirect to="/login" />;
      }
      if (
        error?.hasOwnProperty('response')
        && error?.response
        && error?.response?.hasOwnProperty('data')
        && error?.response?.data
        && error?.response?.data?.hasOwnProperty('error')
        && error?.response?.data?.error
      ) {
        reject(error?.response?.data?.error?.message);
      } else {
        reject(error?.response?.data?.message);
      }
    });
});

export const ApiDownload = (type, userData) => {
  const method = userData && Object.keys(userData).length > 0 ? 'POST' : 'GET';
  return new Promise((resolve, reject) => {
    axios({
      url: BaseURL + type,
      method,
      headers: getHttpOptions(),
      data: userData,
    })
      .then((res) => res.blob())
      .then((res) => resolve(res))
      .catch((error) => {
        if (
          error
          && error.hasOwnProperty('response')
          && error.response
          && error.response.hasOwnProperty('data')
          && error.response.data
          && error.response.data.hasOwnProperty('error')
          && error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message);
        } else {
          reject(error?.response?.data?.message);
        }
      });
  });
};

export const ApiGetBuffer = (url) => new Promise((resolve, reject) => {
  fetch(url, {
    method: 'GET',
    mode: 'no-cors',
  })
    .then((response) => {
      if (response.ok) {
        return response.buffer();
      }
      resolve(null);
    })
    .then((buffer) => {
      resolve(buffer);
    })
    .catch((error) => {
      console.error(error);
      reject(error);
    });
});

export const getHttpOptions = (options = defaultHeaders) => {
  let headers = {};

  if (options.hasOwnProperty('isAuth') && options.isAuth) {
    headers['x-access-token'] = authUtil.getToken();
  }

  // if (options.hasOwnProperty("isAuth") && options.isAuth) {
  //   headers["x-auth-token"] = Auth.getToken();
  // }

  if (options.hasOwnProperty('isJsonRequest') && options.isJsonRequest) {
    headers['Content-Type'] = 'application/json';
    // headers["Content-Type"] = "multipart/form-data";
  }

  if (options.hasOwnProperty('AdditionalParams') && options.AdditionalParams) {
    headers = {
      ...headers,
      ...options.AdditionalParams,
    };
  }

  return {
    headers,
  };
};

export const getHttpOptionsFormdata = (options = defaultHeaders) => {
  let headers = {};

  if (options.hasOwnProperty('isAuth') && options.isAuth) {
    headers['x-access-token'] = authUtil.getToken();
  }

  // if (options.hasOwnProperty("isAuth") && options.isAuth) {
  //   headers["x-auth-token"] = Auth.getToken();
  // }

  if (options.hasOwnProperty('isJsonRequest') && options.isJsonRequest) {
    // headers["Content-Type"] = "application/json";
    headers['Content-Type'] = 'multipart/form-data';
  }

  if (options.hasOwnProperty('AdditionalParams') && options.AdditionalParams) {
    headers = {
      ...headers,
      ...options.AdditionalParams,
    };
  }

  return {
    headers,
  };
};
