import Travel from '../../assets/icons/travel.svg';
import VaccineIcon from '../../assets/icons/vaccine-icon.svg';
import Idverified from '../../assets/icons/idverified.svg';
import Adult from '../../assets/icons/adult.svg';

// Workflow component variable icons

export const IconMap = {
  occasion: Travel,
  user_profile: Adult,
  identity: Idverified,
  proof_of_immunity: VaccineIcon,
};

export const ColumnTitles = [
  'occasion',
  'user_profile',
  'identity',
  'proof_of_immunity',
  'detection',
  'prevention',
];

export const ColumnName = {
  occasion: 'Occasion',
  user_profile: 'User profile',
  identity: 'Identity',
  proof_of_immunity: 'Proof of immunity',
  detection: 'Detection',
  prevention: 'Prevention',
};

// Workflow component variable table header title

export const OccationTable = {
  title: [
    'Title',
    'Comming from',
    'Traveling to',
    'Reason',
    'Transportation',
  ],
  type: 'occasion',
};

export const occationFieldname = {
  title: '',
  commingFrom: '',
  travellingTo: '',
  reason: '',
  transportation: '',
};

export const UserProfileTable = {
  title: [
    'Title',
    'ID',
    'Age Limits',
    'Gender',
    'Citizenship',
    'Physiological conditions',
    'Health conditions',
    'Living conditions',
    'Working conditions',
  ],
  type: 'user_profile',
};

export const UserProfileFieldName = {
  title: '',
  id: '',
  ageLimits: '',
  gender: '',
  Citizenship: '',
  physiologicalConditions: '',
  healthConditions: '',
  Livingconditions: '',
  Workingconditions: '',
};

export const IdentityTable = {
  title: [
    'User group',
    'Age Limits *',
    'Gender',
    'Physiological conditions',
    'Health Conditions',
  ],
  type: 'identity',
};

export const IdentityField = {
  userGroup: '',
  ageLimits: '',
  gender: '',
  physiologicalConditions: '',
  healthConditions: '',
};

export const ImmunityTable = {
  title: [
    'Vaccine',
    'Min Doses',
    'Min Days*',
    'Valid for',
    'Data source',
  ],
  type: 'proof_of_immunity',
};

export const ImmunityField = {
  vaccine: '',
  minDoses: '',
  minDays: '',
  Validfor: '',
  datasource: '',
};
