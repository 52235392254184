/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiPatch } from '../../helper/helpers/api/apiData';

const initialState = {
  getWorkflowApi: {},
};

export const handleUpdateWorkflow = createAsyncThunk(
  'admin/update-passport-credentials',
  async (data, formData) => {
    const response = await ApiPatch(`admin/update-passport-credentials?id=${data}`, formData);
    return response;
  },
);

export const UpdateWorkflow = createSlice({
  name: 'UpdateWorkflow',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(handleUpdateWorkflow.pending, (state) => {
        // state.saveStatus = "loading";
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(handleUpdateWorkflow.fulfilled, (state, action) => {
        // state.saveStatus = "succeeded";
        state.getWorkflowApi = action.payload;
      })
      .addCase(handleUpdateWorkflow.rejected, (state) => {
        // state.saveStatus = "failed";
      });
  },
});

export default UpdateWorkflow.reducer;
