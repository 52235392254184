// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  font-family: var(--primary-font);
}

* {
  box-sizing: border-box;
}

h1,h2,h3,h4,h5,h6,p {
  margin: 0;
}

a {
  text-decoration: none;
}

a {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
}


/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #F0F0F0; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d3d3d3;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,gCAAgC;AAClC;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,SAAS;EACT,UAAU;AACZ;;;AAGA,UAAU;AACV;EACE,UAAU;EACV,WAAW;AACb;;AAEA,UAAU;AACV;EACE,mBAAmB;AACrB;;AAEA,WAAW;AACX;EACE,mBAAmB;AACrB;;AAEA,oBAAoB;AACpB;EACE,mBAAmB;AACrB","sourcesContent":["body {\n  margin: 0;\n  padding: 0;\n  font-family: var(--primary-font);\n}\n\n* {\n  box-sizing: border-box;\n}\n\nh1,h2,h3,h4,h5,h6,p {\n  margin: 0;\n}\n\na {\n  text-decoration: none;\n}\n\na {\n  text-decoration: none;\n}\n\nul {\n  margin: 0;\n  padding: 0;\n}\n\n\n/* width */\n::-webkit-scrollbar {\n  width: 5px;\n  height: 5px;\n}\n\n/* Track */\n::-webkit-scrollbar-track {\n  background: #ffffff; \n}\n \n/* Handle */\n::-webkit-scrollbar-thumb {\n  background: #F0F0F0; \n}\n\n/* Handle on hover */\n::-webkit-scrollbar-thumb:hover {\n  background: #d3d3d3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
