/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React from 'react';
import { Tooltip } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CopyrightIcon from '../../../assets/icons/copyright.svg';
import DeleteIcon from '../../../assets/icons/delete.svg';
import DotIcon from '../../../assets/icons/dot-icon.svg';
import Loader from '../../loader';

export default function SubMenu(props) {
  const {
    openRemoveModal,
    setOpenRemoveModal,
    handleCopyCredential,
    pathName,
    data,
    handleClick,
  } = props;
  const { dataGet } = useSelector((state) => state.credential);

  return (
    <>
      <div className="left-side-submenu-alignment">
        <NavLink
          to="/credentials"
          className={pathName === '/credentials' && 'active-new-set'}
        >
          <span> + New set</span>
        </NavLink>
        <ul>
          {openRemoveModal.loader ? (
            <Loader />
          ) : (
            <>
              {dataGet?.length > 0
                && dataGet?.map((f, i) => (
                    <>
                      <li
                        onClick={() => {
                          handleClick(f);
                        }}
                        className={
                          (pathName === '/workflow'
                            || pathName === '/editattachment')
                          && data
                          && data?._id === f?._id
                            ? 'active-menu'
                            : ''
                        }
                      >
                        <div className="hover-to-text-icon">
                          <NavLink to={'/workflow'}>
                            <div className="sidebar-hover-all-content-alignment">
                              <div className="left-content">
                                <img src={DotIcon} alt="DotIcon" />
                                <span>
                                  {f?.title?.length > 19
                                    ? `${f?.title?.substring(0, 18)}...`
                                    : f?.title}
                                </span>
                              </div>
                              {data?._id === f?._id && (
                                <div className="right-content">
                                  <Tooltip title="Copy">
                                    <img
                                      src={CopyrightIcon}
                                      alt="CopyrightIcon"
                                      className="copy-action-icon"
                                      onClick={() => {
                                        setOpenRemoveModal({
                                          loader: true,
                                        });
                                        handleCopyCredential(f?._id);
                                      }}
                                    />
                                  </Tooltip>

                                  <Tooltip title="Delete">
                                    <img
                                      src={DeleteIcon}
                                      alt="DeleteIcon"
                                      onClick={() => setOpenRemoveModal({
                                        open: true,
                                        id: f?._id,
                                      })
                                      }
                                    />
                                  </Tooltip>
                                </div>
                              )}
                            </div>
                          </NavLink>
                        </div>
                      </li>
                    </>
                ))}
            </>
          )}
        </ul>
      </div>
    </>
  );
}
